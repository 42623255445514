import { useState, useEffect, useCallback, useMemo } from 'react';
import { Drawer, IconButton, List } from '@mui/material';
import classNames from 'classnames';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import { useTheme } from '@mui/styles';
import * as config from '../../config';

// styles
import useStyles from './Sidebar.css';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext/LayoutContext';
import { useAppGlobalState, useAppSelectedSite, useAppSiteState } from '../../context/AppContext/AppContext';
import UserHelper from '../../helpers/UserHelper';
import UsersApi from '../../api/prodUserApi';
import Utils from '../../utilities/utils';
import { useAppMenuStructureState } from '../../structure';
import { useNavigateToLink } from '../../actions/NavigateActions';

function Sidebar() {
  const classes = useStyles();
  const theme: any = useTheme();
  const navigate = useNavigateToLink();

  const [mounted, setMounted] = useState<boolean>(false);

  const [linkCollapseObject, setLinkCollapseObject] = useState<{
    [key: string]: boolean;
  }>({});

  const { selectedSiteId, isAdmin } = useAppGlobalState();
  const selectedSite = useAppSelectedSite();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const siteState = useAppSiteState();

  // local
  const [isPermanent, setPermanent] = useState(true);

  function isSmallScreen() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    return windowWidth < breakpointWidth;
  }

  function handleWindowWidthChange() {
    const isScreenWidthSmall = isSmallScreen();
    if (isScreenWidthSmall && isPermanent) {
      setPermanent(false);
    } else if (!isScreenWidthSmall && !isPermanent) {
      setPermanent(true);
    }
  }

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const gotoLink = useCallback(
    (link) => {
      navigate(link);
    },
    [navigate]
  );

  const onClickLink = (link) => {
    if (isSmallScreen()) {
      toggleSidebar(layoutDispatch);
      setTimeout(() => {
        gotoLink(link);
      });
    } else {
      gotoLink(link);
    }
  };

  const showVrsAccess = useCallback(() => {
    (async () => {
      const canAccessVrsOperations = siteState.vrsAbilities.vrsInternalAccessAbilities.length > 0;

      const canAccessVrs = !!UserHelper.Can('vrs', 'authModule');
      // ReactGA.event({
      //   category: 'VRS',
      //   action: 'VRS Launched',
      // });
      const endpoint = config.default.apiGateway.VRS_URL;
      const vjcToken = await Utils.userTokens();
      if (canAccessVrsOperations) {
        const { token } = await UsersApi.getVrsOperationLoginToken(vjcToken);
        window.open(
          `${endpoint}${
            endpoint && endpoint[endpoint.length - 1] === '/' ? '' : '/'
          }MigratedUserLogin?hideHeader=false&Token=${token}`,
          '_self'
        );
      } else if (canAccessVrs) {
        const { token } = await UsersApi.getVrsLoginToken(vjcToken, selectedSiteId);
        window.open(
          `${endpoint}${
            endpoint && endpoint[endpoint.length - 1] === '/' ? '' : '/'
          }MigratedUserLogin?hideHeader=false&Token=${token}`,
          '_self'
        );
      } else {
        window.open(endpoint, '_self');
      }
    })();
  }, [siteState, selectedSiteId]);

  const showCirrus = useCallback(() => {
    (async () => {
      const canAccessCirrus = UserHelper.Can('edit', 'design');
      if (canAccessCirrus || isAdmin) {
        navigate('/design');
      }
    })();
  }, [isAdmin, selectedSiteId, navigate]);

  const showExchange = useCallback(() => {
    (async () => {
      // ReactGA.event({
      //   category: 'VRS',
      //   action: 'VRS Launched',
      // });
      let endpoint = config.default.apiGateway.API_APP_URL;
      if (!endpoint.startsWith('http')) {
        endpoint = `https://${config.default.apiGateway.API_APP_URL}`;
      }
      window.open(endpoint, '_blank');
    })();
  }, []);

  const callbackArray = useMemo(
    () => ({
      menuAccessVrs: showVrsAccess,
      menuShowCirrus: showCirrus,
      menuExchange: showExchange,
    }),
    [showVrsAccess, showCirrus, showExchange]
  );
  const structure = useAppMenuStructureState(callbackArray);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      console.log('sidebar mounted');
    }

    return () => {
      if (mounted) {
        console.log('sidebar unmounted');
      }
    };
  }, [mounted]);

  return (
    <>
      <Drawer
        variant={isPermanent ? 'permanent' : 'temporary'}
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        })}
        classes={{
          paper: classNames(
            {
              [classes.drawerOpen]: isSidebarOpened,
              [classes.drawerClose]: !isSidebarOpened,
            },
            classes.listContainer
          ),
        }}
        open={isSidebarOpened}
      >
        <div className={classes.toolbar} />
        <div className={classes.mobileBackButton}>
          <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
            <ArrowBackIcon className={classes.mobileBackButtonIcon} />
          </IconButton>
        </div>
        <List>
          {structure.map((link) => {
            const ActionLink = (
              <SidebarLink
                linkCollapseObject={linkCollapseObject}
                setLinkCollapseObject={setLinkCollapseObject}
                key={link.id}
                isSidebarOpened={isSidebarOpened}
                setActiveLink={onClickLink}
                disabled={link.disabled}
                site={selectedSite}
                {...link}
              />
            );

            if (link.requireAdmin) {
              return isAdmin ? ActionLink : null;
            }

            if (link.can) {
              const canSee = UserHelper.Can(link.can.do, link.can.do);
              if (canSee) {
                return ActionLink;
              } else {
                return null;
              }
            }

            return ActionLink;
          })}
        </List>
      </Drawer>
    </>
  );
}

export default Sidebar;
