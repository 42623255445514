// Third party libraries
import moment from 'moment-timezone';
import * as localeMoment from 'moment';
import { v4 as uuid } from 'uuid';
import qstr from 'query-string';
import util from 'util';
import { Logger } from './Logger/Logger';
import * as ENUMS from '../enums/Enums';
import { authModules, claims, federatedIdentityInfo, stageInfo } from 'vccm-common';

import _ from 'lodash';
import authLib, { getUserIdAttributes } from '../libs/authLib';
import {
  EXTERNAL_CALL_TRY_DELAY,
  EXTERNAL_CALL_TRY_LONG_DELAY,
  SECONDS_PER_DAY,
  TimeScaleReleasePlan,
  VRS_ADMINISTRATOR_ACCESS_LEVEL_ID,
  VRS_CSE_ACCESS_LEVEL_ID,
  VRS_MAINTENANCE_ACCESS_LEVEL_ID,
  VRS_USER_ACCESS_LEVEL_ID,
  VRS_VIDEOJET_CONTACT_ADMIN_ACCESS_LEVEL_ID,
  VRS_VIDEOJET_SERVICE_ACCESS_LEVEL_ID,
  reportingQueries,
} from '../constants/global';
import Cookies from 'universal-cookie';
import { ITimeRange } from '../interfaces/Device/ITimeRange';
import { IUser } from '../interfaces/User/IUser';
import { IUserInput } from '../interfaces/User/IUserInput';
import config from '../config';
import ability from '../ability';
import { ISchedule } from '../interfaces/User/ISchedule';
import { IParameterSelectionItem } from '../interfaces/ServiceDashboard/IParameterSelectionItem';
import { IParameterSelection } from '../interfaces/ServiceDashboard/IParameterSelection';
import { IDataTagNameAssignment } from '../interfaces/ServiceDashboard/IDataTagNameAssignment';
import { GridColDef } from '@mui/x-data-grid-pro';
import { IServiceModel } from '../interfaces/ServiceDashboard/IServiceModel';
import { extractErrors } from '../libs/getDynoErrors';
const scheduleTypes: Array<any> = [
  { text: 'Shift', value: 1, color: '#56ca85' },
  { text: 'Maintenance', value: 2, color: '#f8a398' },
  { text: 'Break', value: 3, color: '#cac33b' },
];

const rRuleUntilFormat = 'YYYYMMDD[T]HHmm00[Z]';

export function pad(n: number): string {
  return n < 10 ? `0${n}` : `${n}`;
}

export function isTimescaleUrl() {
  const { pathname } = window.location;
  const releaseElement = TimeScaleReleasePlan.find(
    (el) =>
      (!pathname.includes('/vrs/devicemanagement') &&
        !pathname.includes(`${el.path}-sql`) &&
        pathname.includes(el.path)) ||
      (pathname.includes('/vrs/devicemanagement') &&
        pathname != `${el.path}-sql` &&
        (pathname === el.path || pathname === `${el.path}-ts`))
  );

  return (releaseElement && releaseElement.defaultTarget === 'ts') || pathname.indexOf('-ts') > -1;
}

export function checkTimescale() {
  return window.location.pathname.indexOf('-ts') > -1 ? '-ts' : '';
}

export function checkSql() {
  return window.location.pathname.indexOf('-sql') > -1 ? '-sql' : '';
}

export function cloneObj(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function yyyymmddToddmmyyyy(yyyymmdd: string): string {
  if (!yyyymmdd.includes('T')) {
    yyyymmdd = yyyymmdd + 'T00:00:00';
  }
  const date = new Date(yyyymmdd);
  const dd = pad(date.getDate());
  const mm = pad(date.getMonth() + 1);
  const yyyy = date.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
}

export function dateToyyyyymmdd(date: Date): string {
  const dd = pad(date.getDate());
  const mm = pad(date.getMonth() + 1);
  const yyyy = date.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

export function getBrowserName() {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  } else {
    browserName = 'No browser detection';
  }
  return browserName;
}

export function createUserInput(activeUser: IUser, companyId: string) {
  const userInput: IUserInput = {
    UserId: activeUser.UserId ? activeUser.UserId : '',
    FirstName: activeUser.FirstName,
    LastName: activeUser.LastName,
    EmailAddress: activeUser.EmailAddress,
    NotificationEmail: activeUser.NotificationEmail,
    EnableNotification: activeUser.EnableNotification ? 'on' : 'off',
    PhoneNumber: '',
    UserNotes: activeUser.UserNotes || '',
    Password: '',
    ConfirmPassword: '',
    AccessLevelId: activeUser.AccessLevelId,
    PlantId: 0,
    CompanyId: companyId && companyId !== '0' ? companyId : '1',
    LanguageId: activeUser.LanguageId,
    CountryId: activeUser.CountryId ? activeUser.CountryId : 0,
    Disabled: makeOnOff(activeUser.Disabled),
    IsCountryUser: makeOnOff(activeUser.IsCountryUser),
    DeleteUser: makeOnOff(false),
    RestoreDeletedUser: makeOnOff(false),
    ExtraOptions: '',
    DoesDeleteCompletely: makeOnOff(false),
    PlantIDs: activeUser.PlantIDs ? activeUser.PlantIDs : '',
  };

  return userInput;
}

export function isSpecificTypeOfVRSInternalUser(authClaims: any, userType: string): boolean {
  let result = false;

  try {
    let permission = '0';
    switch (userType) {
      case 'Videojet-ContractAdmin':
        permission = '1';
        break;
      case 'Videojet-Service':
        permission = '2';
        break;
      case 'Videojet':
        permission = '4';
        break;
      case 'Videojet-CSE':
        permission = '8';
        break;
      case 'Videojet-SalesUser':
        permission = '16';
        break;
    }

    result = !!(authClaims && authClaims.VrsInternal && authClaims.VrsInternal.vrsOperations === permission);
  } catch (e) {
    Logger.of('isSpecificTypeOfVRSInternalUser').warn('error', e);
  }

  return result;
}

export function getExternalCallDelay(body) {
  if (body && body.query && reportingQueries.reduce((acc, el) => acc || body.query.includes(el), false)) {
    return EXTERNAL_CALL_TRY_LONG_DELAY;
  }
  return EXTERNAL_CALL_TRY_DELAY;
}

export function addToCurrentYear(extraYear: number): number {
  const now = new Date();
  return now.getFullYear() + extraYear;
}

export function normaliseName(name: string): string {
  return name ? name.toLowerCase().replace(/[\s/,.?()*]/g, '_') : name;
}

export function makeOnOff(value) {
  return value ? 'on' : 'off';
}

export function produceNormalizedName(name: string, truncationLimit: number): string {
  if (name) {
    return normaliseName(
      name.length <= truncationLimit && truncationLimit >= 3 ? name : `${name.substring(0, truncationLimit - 3)}...`
    );
  }

  return name;
}

export function putUSAAndCanadaTop(sortedCountries, key = 'CountryId') {
  // Put United States and Canada top
  const unitedStates = sortedCountries.find((el) => el[key] === 1);
  const canada = sortedCountries.find((el) => el[key] === 2);
  const allCountries = sortedCountries.filter((el) => ![1, 2].includes(el[key]));
  if (canada) {
    allCountries.unshift(canada);
  }

  if (unitedStates) {
    allCountries.unshift(unitedStates);
  }

  return allCountries;
}

export function isExternalAdministrator() {
  const result = ability.rules.find(
    (r) => r.actions === 'vrs' && r.subject === 'authModule' && r.fields && r.fields.includes('Administrator')
  );

  return !!result;
}

export function convertId(id: string): string {
  return id.replace(/\//g, '_').replace(/#/g, '.');
}

export const smallDelay = (delayAmount: number): Promise<boolean> =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    }, delayAmount)
  );

export const singleTick = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    })
  );

export function standardiseWithZero(value: number): string | number {
  return value < 10 ? `0${value}` : value;
}

export function startAndEndTimeFromTimeRange(timeRange: ITimeRange) {
  let startTime: any = '';
  let endTime: any = '';
  if (timeRange.value === 'custom') {
    startTime = Utils.evaluateAsUtcStartOrEndOfDay(timeRange.start, true);
    endTime = Utils.evaluateAsUtcStartOrEndOfDay(timeRange.end, false);
  } else {
    const now = moment();
    endTime = now.toDate();
    startTime = now.subtract(Number(timeRange.value), 'hours').toDate();
  }

  return { startTime, endTime };
}

export function startAndEndTimeFromTimeRangeAsIso(timeRange: ITimeRange) {
  let startTime: any = '';
  let endTime: any = '';
  if (timeRange.value === 'custom') {
    startTime = timeRange.start.toISOString();
    endTime = timeRange.end.toISOString();
  } else {
    const now = moment();
    endTime = now.toISOString();
    startTime = now.subtract(Number(timeRange.value), 'hours').toISOString();
  }

  return { startTime, endTime };
}

function getYYYYMMDD(date: Date): string {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
}

export function startAndEndTimeFromTimeRangeAsYYYYMMDD(timeRange: ITimeRange) {
  let startTime: any = '';
  let endTime: any = '';
  if (timeRange.value === 'custom') {
    startTime = Utils.evaluateAsUtcStartOrEndOfDay(timeRange.start, true).toDate();
    endTime = Utils.evaluateAsUtcStartOrEndOfDay(timeRange.end, false).toDate();
  } else {
    const now = moment();
    endTime = now.toDate();
    startTime = now.subtract(Number(timeRange.value), 'hours').toDate();
  }

  return {
    startTime: getYYYYMMDD(startTime),
    endTime: getYYYYMMDD(endTime),
  };
}

export function formattedDateTimeStringFromMomentObject(momentDate, includeTimePart = true): string {
  const timePart = `${standardiseWithZero(momentDate.hours())}:${standardiseWithZero(momentDate.minutes())}`;
  return includeTimePart
    ? `${yyyymmddToddmmyyyy(momentDate.format('YYYY-MM-DD'))} ${timePart}`
    : yyyymmddToddmmyyyy(momentDate.format('YYYY-MM-DD'));
}

export function parseTabLimitedFileData(fileData) {
  // By lines
  const lines = fileData.split('\n');
  const logData: Array<any> = [];
  const logFieldNames: Array<{ field: string; title: string }> = [];
  for (let line = 0; line < lines.length; line++) {
    // By tabs
    const tabs = lines[line].split('\t');
    const lineData: any = {};
    for (let tab = 0; tab < tabs.length; tab++) {
      if (line === 0) {
        logFieldNames.push({
          field: tabs[tab].trim().replace(/\s/g, '_'),
          title: tabs[tab],
        });
      } else {
        lineData[logFieldNames[tab].field] = tabs[tab];
      }
    }

    if (line !== 0) {
      logData.push(lineData);
    }
  }

  return { logFieldNames, logData };
}

export function formattedLocalDateTimeStringFromZuluDateString(utcDate: string, includeTimePart = true): string {
  const now = moment(utcDate);
  const timePart = `${standardiseWithZero(now.hours())}:${standardiseWithZero(now.minutes())}`;
  return includeTimePart
    ? `${yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'))} ${timePart}`
    : yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'));
}

export function formattedLocalDateTimeStringFromZuluDateStringToYearFirst(
  utcDate: string,
  includeTimePart = true
): string {
  const now = moment(utcDate);
  const timePart = `${standardiseWithZero(now.hours())}:${standardiseWithZero(now.minutes())}`;
  return includeTimePart ? `${now.format('YYYY-MM-DD')} ${timePart}` : now.format('YYYY-MM-DD');
}

export function formatZuluDateStringToLocalTime(utcDate: string, includeTimePart = true): string {
  const now = localeMoment.utc(utcDate).local();
  const timePart = `${standardiseWithZero(now.hours())}:${standardiseWithZero(now.minutes())}`;
  return includeTimePart
    ? `${yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'))} ${timePart}`
    : yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'));
}

export function formattedUTCDateTimeStringFromZuluDateString(utcDate: string, includeTimePart = true): string {
  const now = moment.utc(utcDate);
  const timePart = `${standardiseWithZero(now.hours())}:${standardiseWithZero(now.minutes())}`;
  return includeTimePart
    ? `${yyyymmddToddmmyyyy(now.toISOString())} ${timePart}`
    : yyyymmddToddmmyyyy(now.toISOString());
}

export const getLanguageLongStr = (lan) => {
  if (lan === 'en') {
    return 'en-US';
  }

  return `${lan}-${lan.toUpperCase()}`;
};

export const getLanguageCodeStr = (lan) => {
  if (lan === 'en') {
    return 'en_US';
  }

  return `${lan}_${lan.toUpperCase()}`;
};

export function currentDateTime(): string {
  const now = moment();
  const timePart = `${standardiseWithZero(now.hours())}:${standardiseWithZero(now.minutes())}`;
  return `${yyyymmddToddmmyyyy(now.toISOString())} ${timePart}`;
}

export function isDateExpired(utcDate: string, days, now = moment().utc().unix()) {
  const targetDate = moment.utc(utcDate).unix();
  const expirationDate = targetDate + days * SECONDS_PER_DAY;
  return now > expirationDate;
}

export function currentDate(): string {
  const now = moment();
  return yyyymmddToddmmyyyy(now.toISOString());
}

export function buildUrl(valueArray: Array<any>): string {
  let path = '';
  for (const value of valueArray) {
    // eslint-disable-line no-restricted-syntax
    if (value || value === 0) {
      path = `${path}/${value}`;
    } else {
      return path;
    }
  }

  return path;
}

export function produceTooltip(pre: string, values: Array<string>, post: string): string {
  return values.reduce((acc, el, index) => {
    let seperator = ', ';
    if (index === values.length - 2) {
      seperator = ' or ';
    } else if (index === values.length - 1) {
      seperator = post;
    }
    return acc + el + seperator;
  }, pre);
}

export function equalsIgnoreCase(value1: null | undefined | string, value2: string): boolean {
  if (value1 === value2) {
    return true;
  }

  if (!value1 || !value2) {
    return false;
  }
  return value1.toLowerCase() === value2.toLowerCase();
}

export function isNotNullOrUndefined(obj: any): boolean {
  return obj !== null && obj !== undefined;
}

export function getEmptyScheduleList(): ISchedule[] {
  return [0, 1, 2, 3, 4, 5, 6].map((el) => ({
    Day: el,
    StartHour: 0,
    StartMinute: 0,
    ShiftHours: 0,
    ShiftMins: 0,
  }));
}

/**
 * Modifies an object to remove properties that are empty
 *
 * @param {object} obj -  to be modified
 * @param {string|[string]=} propertyNames - optional list of properties to update, otherwise all
 * @returns {object} updated object
 */

export const prepToSend = (obj, propertyNames) => {
  const pNames = !propertyNames ? Object.keys(obj) : Array.isArray(propertyNames) ? propertyNames : [propertyNames];
  for (const name of pNames) {
    if (obj[name] != null && obj[name].length === 0) {
      obj[name] = null;
    }
  }
  return obj;
};

export function isTargetUserExternal(accessLevelId) {
  return (
    accessLevelId === VRS_ADMINISTRATOR_ACCESS_LEVEL_ID ||
    accessLevelId === VRS_MAINTENANCE_ACCESS_LEVEL_ID ||
    accessLevelId === VRS_USER_ACCESS_LEVEL_ID
  );
}

export function isTargetUserExternalAdministrator(accessLevelId) {
  return accessLevelId === VRS_ADMINISTRATOR_ACCESS_LEVEL_ID;
}

export function isVideojetContactUser(accessLevelId) {
  return accessLevelId === VRS_VIDEOJET_CONTACT_ADMIN_ACCESS_LEVEL_ID;
}

export function isVideojetServiceUser(accessLevelId) {
  return accessLevelId === VRS_VIDEOJET_SERVICE_ACCESS_LEVEL_ID;
}

export function isVideojetCSEUser(accessLevelId) {
  return accessLevelId === VRS_CSE_ACCESS_LEVEL_ID;
}

export function checkIsBrowserSafari() {
  return getBrowserName() === 'safari';
}

class Utils {
  static flattenMessages(nestedMessages, prefix = '') {
    Logger.of('Utils.flattenMessages').info(`prefix is ${prefix}, nestedMessages=>`, nestedMessages);
    if (!nestedMessages) {
      return {};
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'string') {
        messages[prefixedKey] = value;
      } else {
        Object.assign(messages, this.flattenMessages(value, prefixedKey));
      }
      return messages;
    }, {});
  }

  static extractErrors(error, mixedMode = false) {
    if (error == null) return error;

    let errStr = '';
    try {
      if (error.statusCode && error.statusCode !== 200) {
        errStr = `Code: ${error.statusCode} : `;
      }
      if (error.message) {
        try {
          const err = JSON.parse(error.message);
          if (err.message) {
            errStr += `Message: ${err.message}`;
          } else {
            errStr += `Message: ${error.message}`;
          }
        } catch (e) {
          if (mixedMode) {
            errStr += error.message;
          } else {
            throw e;
          }
        }
      }
    } catch (e) {
      return `Error building error string: ${util.inspect(e)}`;
    }
    return errStr;
  }

  static isLocalhost() {
    return window.location.host.includes('localhost');
  }

  static isDev() {
    return this.isLocalhost() || window.location.host.includes('vccm.dev');
  }

  static isOrderExEnabled() {
    return false;
  }

  static getStage() {
    if (window.location.host.toLowerCase().includes('localhost') || window.location.host.includes('vccm.dev'))
      return stageInfo.Stages.dev;
    if (window.location.host.toLowerCase().includes('vccm.qa')) return stageInfo.Stages.qa;
    if (window.location.host.toLowerCase().includes('vccm.stage')) return 'stage';
    if (window.location.host.toLowerCase().includes('vccm.prodtest')) return stageInfo.Stages.prodtest;
    if (window.location.host.toLowerCase().includes('vccm.indy')) return stageInfo.Stages.indy;

    if (window.location.host.toLowerCase().includes('videojetcloud')) return stageInfo.Stages.production;
  }
  /**
   * adds a event to each event in timeInStateData for each event in oeeData that oee is less then the threshold
   *
   * @function
   * @param {Array} timeInStateData - timeInStateEvents
   * @param {Array} processMinuteData - data from shiftTargets endpoint
   * @param {number} threshold - value multiplied by the run rate to determine the slow cycle rate
   */
  static addSlowCycleToTimeInStateEvents(timeInStateData, processMinuteData) {
    const mergedData: Array<any> = [];
    if (!processMinuteData) return timeInStateData;
    // let expectedPiecesPerMinutes = (processMinuteData.rates.runRate / 60) * threshold;
    // sort OeeData by eventStartDT
    _.forEach(timeInStateData, (timeInStateEvent) => {
      if (
        timeInStateEvent.type === 'schedule' &&
        timeInStateEvent.typeId === scheduleTypes.find((i) => i.text === 'Shift').value
      ) {
        // add an event from the time of the first timeInStateData event until the first oeeDataEvent
        // console.log('got an production schedule event =>', timeInStateEvent);
        const newEvents = Utils.addSlowCycleToTimeInStateEvent(timeInStateEvent, processMinuteData);
        // console.log('Here are your events =>', newEvents);
        _.forEach(newEvents, (event) => {
          mergedData.push(event);
        });
      } else {
        mergedData.push(timeInStateEvent);
      }
    });

    return mergedData;
  }

  /**
   * adds a event to each event in timeInStateData for each event in oeeData that oee is less then the threshold
   *
   * @function
   * @param {Array} timeInStateData - timeInStateEvents
   * @param {Array} processMinuteData - data from shiftTargets endpoint
   */
  static addProcessMinuteDataToTimeInStateEvents(timeInStateData, processMinuteData) {
    const mergedData: Array<any> = [];
    if (!processMinuteData) return timeInStateData;
    // let expectedPiecesPerMinutes = (processMinuteData.rates.runRate / 60) * threshold;
    // sort OeeData by eventStartDT
    _.forEach(timeInStateData, (timeInStateEvent) => {
      if (
        timeInStateEvent.type === 'schedule' &&
        timeInStateEvent.typeId === scheduleTypes.find((i) => i.text === 'Shift').value
      ) {
        // add an event from the time of the first timeInStateData event until the first oeeDataEvent
        // console.log('got an production schedule event =>', timeInStateEvent);
        const newEvents = Utils.addProcessMinuteDataToTimeInStateEvent(timeInStateEvent, processMinuteData);
        // console.log('Here are your events =>', newEvents);
        _.forEach(newEvents, (event) => {
          mergedData.push(event);
        });
      } else {
        mergedData.push(timeInStateEvent);
      }
    });

    return mergedData;
  }

  /**
   * adds a slice event of type microStop for each event less than the given time-span to the passed events
   *
   * @function
   * @param {Array} timeInStateEvent - starting event before slow cycles
   * @param {Array} processMinuteData - data to get counts from
   * @param {number} expectedPiecesPerMinutes - minimal count/minute to not get categorized as a slow cycle
   * @param {boolean} combineSlices - do you want the concurrent events of the same type merged.. TODO show slow cycle percent. for now combine slices
   */
  static addSlowCycleToTimeInStateEvent(timeInStateEvent, processMinuteData, combineSlices = true) {
    // console.log('got an production schedule event =>', timeInStateEvent);
    // look for events in oeeData that are in this range
    // let oeeDataInRange.find(item => item.)
    let eventToAdd;
    let firstEvent = true;
    const returnData: Array<any> = [];
    let lastEvent;
    let lastOeeEvent;
    const beforeTime = moment(timeInStateEvent.startTime);
    const afterTime = moment(timeInStateEvent.endTime);
    // let containsOeeEvent = false;
    if (processMinuteData) {
      // for performance we will slice off events already processed this will only work if the events are in order
      // _.forEach(oeeData.actual.slice(currentOeeDataIndex), function(oeeEvent) {
      _.forEach(processMinuteData, (slowCycle) => {
        const time = moment(slowCycle.dt);
        // look for events in oeeData that are in this range
        if (time.isBetween(beforeTime, afterTime)) {
          if (lastOeeEvent) {
            const lastEventTime = moment(lastOeeEvent.dt);
            eventToAdd = {
              startTime: lastEventTime.toISOString(),
              change: timeInStateEvent.change,
              id: timeInStateEvent.id,
              type: 'slowCycle',
              typeId: 1,
              endTime: time.toISOString(),
            };

            // Add First Event Padding
            if (firstEvent) {
              // the first event
              firstEvent = false;
              const firstEventPadding = {
                startTime: beforeTime.toISOString(),
                change: timeInStateEvent.change,
                id: timeInStateEvent.id,
                type: 'schedule',
                typeId: 1,
                endTime: eventToAdd.startTime, // afterTime.toISOString(),
              };
              returnData.push(firstEventPadding);
            }

            // Combine this and last events if of the same type
            if (combineSlices && lastEvent !== undefined) {
              if (lastEvent.type === eventToAdd.type) {
                // the same
                eventToAdd.startTime = lastEvent ? lastEvent.startTime : eventToAdd.startTime;
                returnData.pop();
              }
            }

            returnData.push(eventToAdd);
          }

          lastOeeEvent = slowCycle;
          lastEvent = eventToAdd;
        }
      });

      // look to add last event padding
      if (lastEvent !== undefined && eventToAdd) {
        if (timeInStateEvent.endTime > eventToAdd.endTime) {
          const lastEventPadding = {
            startTime: eventToAdd.endTime,
            change: timeInStateEvent.change,
            id: timeInStateEvent.id,
            type: 'schedule',
            typeId: 1,
            endTime: timeInStateEvent.endTime,
          };
          returnData.push(lastEventPadding);
        }
      }
    } else {
      returnData.push(timeInStateEvent);
    }

    return returnData.length > 0 ? returnData : [timeInStateEvent];
  }

  static addProcessMinuteDataToTimeInStateEvent(timeInStateEvent, processMinuteData, combineSlices = false) {
    let eventToAdd;
    let firstEvent = true;
    const returnData: Array<any> = [];
    let lastEvent;
    let lastOeeEvent;
    const beforeTime = moment(timeInStateEvent.startTime);
    const afterTime = moment(timeInStateEvent.endTime);
    if (processMinuteData) {
      // for performance we will slice off events already processed this will only work if the events are in order
      _.forEach(processMinuteData, (slowCycle) => {
        const time = moment(slowCycle.dt);
        // look for events in oeeData that are in this range AND that is NOT a microstop
        if (time.isBetween(beforeTime, afterTime) && slowCycle.tcp > 0) {
          if (lastOeeEvent) {
            const lastEventTime = moment(lastOeeEvent.dt);
            eventToAdd = {
              startTime: lastEventTime.toISOString(),
              change: timeInStateEvent.change,
              id: timeInStateEvent.id,
              // type: slowCycle.tcp > 0 ? `slowCycle ${(slowCycle.tcp * 100).toFixed(0)}%` : 'microStop',
              type: `slowCycle ${(slowCycle.tcp * 100).toFixed(0)}%`,
              typeId: 1,
              endTime: lastEventTime.add(1, 'minutes').toISOString(),
            };

            // Add First Event Padding
            if (firstEvent) {
              // the first event
              firstEvent = false;
              const firstEventPadding = {
                startTime: beforeTime.toISOString(),
                change: timeInStateEvent.change,
                id: timeInStateEvent.id,
                type: 'schedule',
                typeId: 1,
                endTime: eventToAdd.startTime,
              };
              returnData.push(firstEventPadding);
            } else {
              // Add Padding of default event
              // eslint-disable-next-line
              if (lastEvent.endTime < eventToAdd.startTime) {
                // put the original event back in place
                const originalEventPadding = {
                  startTime: lastEvent.endTime,
                  change: timeInStateEvent.change,
                  id: timeInStateEvent.id,
                  type: 'schedule',
                  typeId: 1,
                  endTime: eventToAdd.startTime,
                };
                returnData.push(originalEventPadding);
              }
            }

            // Combine this and last events if of the same type
            if (combineSlices && lastEvent !== undefined) {
              if (lastEvent.type === eventToAdd.type) {
                // the same
                eventToAdd.startTime = lastEvent ? lastEvent.startTime : eventToAdd.startTime;
                returnData.pop();
              }
            }

            returnData.push(eventToAdd);
          }

          lastOeeEvent = slowCycle;
          lastEvent = eventToAdd;
        }
      });

      // look to add last event padding
      if (lastEvent !== undefined && eventToAdd) {
        if (timeInStateEvent.endTime > eventToAdd.endTime) {
          const lastEventPadding = {
            startTime: eventToAdd.endTime,
            change: timeInStateEvent.change,
            id: timeInStateEvent.id,
            type: 'schedule',
            typeId: 1,
            endTime: timeInStateEvent.endTime,
          };
          returnData.push(lastEventPadding);
        }
      }
    } // else {
    returnData.push(timeInStateEvent);
    // }

    return returnData.length > 0 ? returnData.sort(Utils.sortByStartTime) : [timeInStateEvent];
  }

  // function for dynamic sorting
  static compareValues(key, order = 'asc') {
    return function (a, b) {
      //eslint-disable-next-line no-prototype-builtins
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  static sortObjectsArray(objectsArray, sortKey) {
    // Quick Sort:
    let retVal;
    if (objectsArray.length > 1) {
      const pivotIndex = Math.floor((objectsArray.length - 1) / 2); // middle index
      const pivotItem = objectsArray[pivotIndex]; // value in the middle index
      const less: Array<any> = [];

      const more: Array<any> = [];

      objectsArray.splice(pivotIndex, 1); // remove the item in the pivot position
      objectsArray.forEach((value) => {
        (value[sortKey] && value[sortKey].toUpperCase()) <= (pivotItem[sortKey] && pivotItem[sortKey].toUpperCase()) // compare the 'sortKey' proiperty
          ? less.push(value)
          : more.push(value);
      });

      retVal = this.sortObjectsArray(less, sortKey).concat([pivotItem], this.sortObjectsArray(more, sortKey));
    } else {
      retVal = objectsArray;
    }
    return retVal;
  }

  static getColorFromString(colorName) {
    let hash = 0;
    for (let i = 0; i < colorName.length; i++) {
      hash = colorName.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
  }

  getScheduleStart(scheduleItem) {
    return moment(scheduleItem.instanceInfo ? scheduleItem.instanceInfo.startTime : scheduleItem.startTime).utc();
  }

  getScheduleEnd(scheduleItem) {
    return moment(scheduleItem.instanceInfo ? scheduleItem.instanceInfo.endTime : scheduleItem.endTime).utc();
  }

  getInstanceStart(scheduleItem) {
    return moment(scheduleItem.instances ? scheduleItem.instances[0].startTime : scheduleItem.startTime).utc();
  }

  getInstanceEnd(scheduleItem) {
    return moment(scheduleItem.instances ? scheduleItem.instances[0].endTime : scheduleItem.endTime).utc();
  }

  explodeDowntimeToTimeLine(downtimes, addMs) {
    return downtimes.map((dt) => {
      let startDt;
      if (addMs) {
        startDt = moment.utc(dt.startDt).add(1, 'ms');
      } else {
        startDt = moment.utc(dt.startDt);
      }
      const minItem = {
        startTime: startDt,
        startDt: dt.startDt,
        endTime: moment.utc(dt.endDt),
        id: dt.id || uuid({ msecs: startDt.valueOf() }),
        type: dt.type || 'downtime',
        reasonId: dt.reasonId,
        status: dt.status,
        shiftId: dt.shiftId,
      };
      return minItem;
    });
  }

  explodeEventToTimeLine(scheduleItem) {
    // abusing object deconstructing in an anonymous function
    // to pick out only the typeId and id from the scheduleItem
    const minItem: any = (({ typeId, id }) => ({ typeId, id }))(scheduleItem);
    // in this case, the shift start is set to the time autostandby ends
    minItem.startTime = this.getScheduleStart(scheduleItem);
    minItem.endTime = this.getScheduleEnd(scheduleItem);
    minItem.type = 'schedule';
    let timeLine = [minItem];
    if (scheduleItem.instanceInfo && scheduleItem.instanceInfo.concurrentEvents) {
      timeLine = timeLine.concat(
        ...scheduleItem.instanceInfo.concurrentEvents.map((ce) => this.explodeEventToTimeLine(ce))
      );
    }
    // console.log('minimized %j => %j', scheduleItem, timeLine);
    return timeLine;
  }

  explodeShiftHistoryEventsToTimeLine(scheduleItems) {
    let timeLine: Array<any> = [];
    scheduleItems.forEach((scheduleItem) => {
      const minItem: any = (({ typeId, id }) => ({ typeId, id }))(scheduleItem);
      minItem.startTime = this.getInstanceStart(scheduleItem);
      minItem.endTime = this.getInstanceEnd(scheduleItem);
      minItem.type = 'schedule';

      timeLine.push(minItem);
      if (scheduleItem.instanceInfo && scheduleItem.instanceInfo.concurrentEvents) {
        timeLine = timeLine.concat(
          ...scheduleItem.instanceInfo.concurrentEvents.map((ce) => this.explodeEventToTimeLine(ce))
        );
      }
    });
    Logger.of('Utils.explodeShiftHistoryEventsToTimeLine').info('schedule item for timeline %j', scheduleItems);
    Logger.of('Utils.explodeShiftHistoryEventsToTimeLine').info('shift history timeline %j', timeLine);
    return timeLine;
  }

  /**
   * Take a url string and return it's individual path components
   *
   * @param urlString
   * @returns {{hostname: string, protocol: string, search: string, port: string, origin: string, host: string, params: *, hash: string, pathname: string}}
   */
  static parseUrl(urlString) {
    const a = document.createElement('a');
    a.setAttribute('href', urlString);
    const { host, hostname, pathname, port, protocol, search, hash } = a;
    const origin = `${protocol}//${hostname}${port.length ? `:${port}` : ''}`;
    const params = qstr.parse(search);
    return {
      origin,
      host,
      hostname,
      params,
      pathname,
      port,
      protocol,
      search,
      hash,
    };
  }

  /**
   * Take a time line of events and create a collection of segments defining every state change
   *
   * @param timeLine - ordered collection of schedule and downtime events
   * @param now
   * @returns {*}
   */
  segmentTimeLine(timeLine, now) {
    Logger.of('Utils.segmentTimeLine').info('segmenting the timeline %j', timeLine);
    if (!timeLine || timeLine.length < 1) return timeLine;

    // flatten timeline
    const points: any = []
      .concat(
        ...timeLine
          .map((e) => {
            const copiedFields = {
              id: e.id,
              type: e.type,
              typeId: e.typeId,
              reasonId: e.reasonId,
              status: e.status,
              shiftId: e.shiftId,
              startDt: e.startDt || null,
            };
            return [
              Object.assign(
                {
                  startTime: moment.utc(e.startTime),
                  change: 'start',
                },
                copiedFields
              ),
              Object.assign(
                {
                  startTime: moment.utc(e.endTime || now.clone()),
                  change: 'end',
                },
                copiedFields
              ),
            ];
          })
          .concat([
            {
              startTime: now.clone(),
              change: 'start',
              type: 'now',
            },
            {
              startTime: now.clone().add(1, 'ms'),
              change: 'end',
              type: 'now',
            },
          ])
      )
      .sort(this.timeSorter);

    // segment
    // from event start to end subdivide flattened timeline up-to now
    // explode to a new timeline
    Logger.of('Utils.segmentTimeLine').info('Building segments...');
    const segments: Array<any> = [];
    const ongoingEventStack: Array<any> = [];
    while (points.length) {
      let thisPoint: any;
      try {
        thisPoint = points.shift();
        if (segments.length) {
          // previous segment's end time defaults to real end of event
          // setting previous end to match this start
          segments[segments.length - 1].endTime = thisPoint.startTime;
        }
        if (thisPoint.change === 'start') {
          segments.push(Object.assign({}, thisPoint));
          // console.log('Starting event: thisPoint<%s> = %j', thisPoint.id, thisPoint);
          ongoingEventStack.unshift(thisPoint); // push onto stack of events ongoing
        } else if (thisPoint.change === 'end') {
          if (ongoingEventStack.length) {
            // need to remove ending event from stack
            // ongoing events might end in different order than they are added
            const endingEventIdx = ongoingEventStack.findIndex((e) => e.id === thisPoint.id);
            // let endingEvents;
            if (endingEventIdx >= 0) {
              // endingEvents = ongoingEventStack.splice(endingEventIdx, 1);
              // removed items returned, array modified in place
              // console.log('Ending event %j', endingEvents);
            } else throw new Error("Received unpaired end state (couldn't find start)");

            // continue any concurrent superseded events
            if (ongoingEventStack.length && points.length && points[0].startTime !== thisPoint.startTime) {
              const continuingEvent = Object.assign({}, ongoingEventStack[0]);
              continuingEvent.startTime = thisPoint.startTime;
              segments.push(continuingEvent);
              // console.log('Continuing event<%s> on new segment %j', continuingEvent.id, continuingEvent);
            }
            // else No break between this 'end' and next start, so just wait for next iteration
          } else throw new Error('Received unpaired end state (nothing started)');
        }
      } catch (e) {
        Logger.of('Utils.segmentTimeLine').error('Error "%s" on point %j', e.message, thisPoint);
      }
    }

    Logger.of('Utils.segmentTimeLine').info(segments);
    return segments;
  }

  static getItemById(items, id) {
    if (!items || !items.length || !id) return null;

    const item = items.find((item) => item.id === id);
    if (item) return item;
    return null;
  }

  static splitCurrentTimeSeriesEvent(outageEvents, endTime) {
    const newEvents: Array<any> = [];
    // split time event into start till Now and not till end
    outageEvents.forEach((item) => {
      const now = new Date();
      // ⌛
      if (item.typeId === 1 && moment(item.startTime).toDate() < now && moment(item.endTime).toDate() > now) {
        // Split this event
        /*            console.log('sorting this data', item); */
        newEvents.push(
          Object.assign({}, item, {
            endTime: endTime || now.toISOString(),
          })
        );
        newEvents.push(Object.assign({}, item, { startTime: now.toISOString() }));
      } else {
        newEvents.push(item);
      }
    });
    /*    console.log('splitCurrentTimeSeriesEvent new events', newEvents); */
    return newEvents;
  }

  /**
   * Sort objects by startTime 8am->9am
   *
   * @param {object} a - compare object A
   * @param {object} b - compare object B
   * @returns {number} - compare result
   */
  static sortByStartTime(a, b) {
    return moment(a.startTime).toDate().valueOf() - moment(b.startTime).toDate().valueOf();
  }

  /**
   * Sorting function for Array.sort(), which expects a positive or negative value
   *
   * @param a - left element
   * @param b - right element
   * @returns {number}
   */
  timeSorter(a, b) {
    // +1 if a after b, -1 if a before b, 0 if equal
    return +(a.startTime > b.startTime) || -(a.startTime < b.startTime);
  }

  static sortByName(a, b) {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  /**
   * Sort objects by title a->a
   *
   * @param {object} a - compare object A
   * @param {object} b - compare object B
   * @returns {number} - compare result
   */
  static sortByTitle(a, b) {
    const x = a.title.toLowerCase();
    const y = b.title.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  /**
   * Sort objects by title a->a
   *
   * @param {object} a - compare object A
   * @param {object} b - compare object B
   * @returns {number} - compare result
   */
  static sortByText(a, b) {
    const x = a.text.toLowerCase();
    const y = b.text.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  static isGuid(stringToTest) {
    if (stringToTest[0] === '{') {
      stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    const regexGuid =
      /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  }

  static OnlyLinesInShift(line, schedulerFormattedShifts) {
    if (!schedulerFormattedShifts || !line || !line.printerId) return 1;
    let returnValue = 0;
    _.forEach(schedulerFormattedShifts, (shift) => {
      if (moment(shift.start) < moment() && moment() < moment(shift.end) && !returnValue) {
        returnValue = shift.lineIds && shift.lineIds.find((l) => l === line.id) ? 1 : 0;
      }
    });
    return returnValue;
  }

  //timezone, site, to local functions

  /**
   *  Returns a Date Object in the local tz site time (client local timezone, time value of site's time)
   *
   * @param {string|Date|Moment} time - the time (utc or client local ) to convert to a site local time
   * @param {string} timezone - the site tz
   * @returns {Date} - local tz site time Date
   * @example
   * // '2020-09-01T00:00:00.0000' client local (America/New_York in this example) would be fine too
   * // utc => 2020-08-31T21:00:00-07:00 => to client local => 2020-08-31T21:00:00-04:00
   * getLocalSiteDateObject('2020-09-01T04:00:00.000Z', 'America/Los_Angeles');
   */
  static getLocalSiteDateObject = (time, timezone) => {
    // parse the time, convert to site tz, then back to local tz and offset, but keeping the same time
    return moment(time).tz(timezone).local(true).toDate();
  };

  /**
   * Parse a date string, preventing and ignoring errors
   *
   * @param {string} endDate - date string
   * @param {any} format - possible input date format
   * @returns {*} valid date string or undefined
   */
  static parseDateStr(endDate, format = undefined) {
    try {
      if (endDate) {
        const dt = moment.utc(endDate, format).local();
        if (dt.isValid()) {
          return dt.format('MM/DD/YYYY');
        }
      }
    } catch (e) {
      // do nothing
      console.log(e);
    }
    return undefined;
  }

  // Some RRule related functions

  /**
   * Pull end-date info from an recurrenceRule
   *
   * @param {string} recurrenceRule - the rule string
   * @param {string} returnType - what we lookin for
   * @param {object} ends - obj to receive some flags
   * @returns {((string|number))} date string
   */
  static getENDDates(recurrenceRule, returnType, ends) {
    let returnString: any = 0;
    if (!recurrenceRule) return null;
    if (returnType === 'UNTIL=') returnString = null;
    const array = recurrenceRule.split(';');
    // eslint-disable-next-line
    array
      .filter((element) => element.includes(returnType))
      .forEach((element) => {
        const [, value] = element.split('=');
        // eslint-disable-next-line
        switch (returnType) {
          case 'COUNT=':
            ends.never = false;
            ends.after = true;
            ends.on = false;
            returnString = parseInt(value, 10);
            // eslint-disable-next-line
            if (isNaN(returnString)) returnString = 0;
            break;
          case 'UNTIL=':
            ends.never = false;
            ends.after = false;
            ends.on = true;
            ends.endDate = value.indexOf('Invalid') === -1 ? moment.utc(value, rRuleUntilFormat) : '';
            returnString = value.indexOf('Invalid') === -1 ? this.parseDateStr(value) : '';
            break;
        }
      });
    return returnString;
  }

  static makeDatesFREQForDisplay(recurrenceRule) {
    if (recurrenceRule === undefined || recurrenceRule === null) return 0;
    let returnString = '';
    if (recurrenceRule === '') returnString = 'ONE TIME';
    if (recurrenceRule.includes('FREQ=')) {
      [, returnString] = recurrenceRule
        .split(';')
        .filter((r) => r.includes('FREQ='))[0]
        .split('=');
    }
    switch (returnString.toUpperCase()) {
      case 'DAILY':
        return '1';
      case 'WEEKLY':
        return '2';
      case 'MONTHLY':
        return '3';
      case 'YEARLY':
        return '4';
      default:
    }
    return '0';
  }

  static getFREQRepeat(_, count, endDate, _T) {
    if (count && count > 0) {
      return `${count.toString()} ${_T('scheduler.recurrenceEditorEndOccurrence')}`;
    }
    if (endDate) {
      return `${_T('Ends')} ${endDate}`;
    }
    return _T('scheduler.recurrenceEditorEndNever');
  }

  // turn rrule abbreviations into day of week numbers
  static dayMap = { SU: 0, MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6 };
  static makeDatesBYDAYForDisplay(recurrenceRule, _T, locale) {
    let byDay = '';
    if (!recurrenceRule) return _T ? _T('OneTime') : 'ONE TIME';
    if (recurrenceRule.toLowerCase().includes('never'))
      return _T ? _T('scheduler_recurrenceEditorFrequenciesNever') : 'NEVER';
    // extract the byDay
    if (recurrenceRule.includes('BYDAY=')) {
      [, byDay] = recurrenceRule
        .split(';')
        .filter((r) => r.includes('BYDAY='))[0]
        .split('=');
    }

    if (recurrenceRule.includes('FREQ=')) {
      const [, recurFreq] = recurrenceRule
        .split(';')
        .filter((r) => r.includes('FREQ='))[0]
        .split('=');
      // all days (like DAILY)
      if (recurFreq === 'DAILY' && byDay.length < 1) byDay = 'SU,MO,TU,WE,TH,FR,SA';
    }

    if (!_T) return byDay;

    // create a locale aware moment
    //set the locale to match the user's
    localeMoment.locale(locale);
    // split the rrule string, convert to locale of user, format to two day abbreviation, and back to ', ' separated string
    return (
      byDay &&
      byDay
        .split(',')
        .map((d) => localeMoment.weekdaysShort(this.dayMap[d]))
        .join(', ')
    );
  }
  static makeDayForDisplay(dayAbbreviation, _T, locale) {
    // create a locale aware moment
    //set the locale to match the user's
    localeMoment.locale(locale);
    return localeMoment.weekdaysShort(this.dayMap[dayAbbreviation]);
  }

  static makeDatesFREQForDisplayRow(recurrenceRule, _T) {
    let returnString = '';
    if (!recurrenceRule) return _T('OneTime');
    if (recurrenceRule.toLowerCase().includes('never')) return _T('scheduler.recurrenceEditorEndNever');
    if (recurrenceRule.includes('FREQ=')) {
      [, returnString] = recurrenceRule
        .split(';')
        .filter((r) => r.includes('FREQ='))[0]
        .split('=');
    }
    switch (returnString.toUpperCase()) {
      case 'DAILY':
        return _T('scheduler.recurrenceEditorFrequenciesDaily');
      case 'WEEKLY':
        return _T('scheduler.recurrenceEditorFrequenciesWeekly');
      case 'MONTHLY':
        return _T('scheduler.recurrenceEditorFrequenciesMonthly');
      case 'YEARLY':
        return _T('scheduler.recurrenceEditorFrequenciesYearly');
      default:
        return returnString;
    }
  }

  static getDAYS(byDay) {
    const array: Array<string> = [];
    'SU,MO,TU,WE,TH,FR,SA'.split(',').forEach((day) => {
      if (byDay[day] === true) array.push(day);
    });
    return array.join(',');
  }

  static getFREQ(repeatId) {
    switch (repeatId) {
      case '1':
        return 'DAILY';
      case '2':
        return 'WEEKLY';
      case '3':
        return 'MONTHLY';
      case '4':
        return 'YEARLY';
      default:
    }
    return 'ONE TIME';
  }

  /**
   * converts a client local time to site time to ISO string, like from a time picker
   *
   * @param {string|Date|Moment} time - the utc or client local time that represents a site time
   * @param {string} timezone - the site tz to convert
   * @returns {string} ISO utc time, corrected for site tz
   * @example
   * // '2020-09-01T00:00:00.0000' client local (America/New_York in this example)
   * // would be fine too => 2020-09-01T00:00:00-07:00 => 2020-09-01T07:00:00.000Z
   * Utils.getFinalTimeForServer(new Date('2020-09-01T04:00:00.000Z'), 'America/Los_Angeles');
   */

  static getFinalTimeForServer(time, timezone) {
    return moment(time).tz(timezone, true).utc().toISOString();
  }

  static ParseSimpleDateStringAsUTC(dateStr: string) {
    const regex = /:(\w+)Z/g;

    if (regex.test(dateStr)) {
      const secondArray = Array.from(dateStr.matchAll(/:(\w+)Z/g));
      if (secondArray && secondArray.length > 0 && secondArray[0].length > 1) {
        return new Date(dateStr.replace(regex, `:${secondArray[0][1]}.000Z`));
      }
    }

    if (dateStr.indexOf('.') === -1) {
      return new Date(`${dateStr}.000Z`);
    }

    return new Date(dateStr.replace(/\.\d+Z?/g, '.000Z'));
  }

  static getISOTimeForReport(time, timezone, intervalValue) {
    if (intervalValue === ENUMS.INTERVALS.HOUR) {
      return Utils.getFinalTimeForServer(time, timezone);
    }
    // for non hour we use date as it is without any UTC or timezone calculation
    // because we want only the date part as appeared in the submission form
    return moment(time).toISOString();
  }

  static getFinalTimeForServerChkUtc(time, timezone) {
    if (time.toString().endsWith('Z')) {
      return time;
    }
    return Utils.getFinalTimeForServer(time, timezone);
  }

  static getRecurrenceRule(FREQ, state) {
    const { count, byDay, endDate, ends } = state;
    if (FREQ !== '') FREQ = `FREQ=${FREQ};`;
    let COUNT = count;
    if (COUNT !== 0) {
      COUNT = `COUNT=${COUNT};`;
    } else {
      COUNT = '';
    }
    let BYDAY = this.getDAYS(byDay);
    if (BYDAY.length !== 0) {
      BYDAY = `BYDAY=${BYDAY};`;
    } else {
      BYDAY = '';
    }
    let UNTIL = this.getUNTIL(endDate);
    if (UNTIL != null && ends && ends.on === true) {
      UNTIL = `UNTIL=${UNTIL};`;
    } else {
      UNTIL = '';
    }
    let WKST = 'WKST=SU';
    if (COUNT === '' && UNTIL === '' && FREQ === '') WKST = '';
    return { FREQ, COUNT, BYDAY, UNTIL, WKST };
  }

  static getUNTIL(endDate) {
    // yyyy-MM-ddTHH:mm:sszzz 20180705T035959Z

    if (endDate != null || endDate !== undefined) {
      return moment.utc(endDate).format(rRuleUntilFormat);
    }
    return null;
  }

  static getEndDatePropFromObject(obj, name) {
    return obj && obj.recurrenceRule && Utils.getENDDates(obj.recurrenceRule, name, {});
  }

  static timeToFormat(time, zone) {
    return moment.utc(time).tz(zone).format('hh:mm A');
  }

  static err(prefix: string, name: string): any {
    return { message: `validation.${prefix}.${name}` };
  }

  static deserializeClaimsForAuthModule(authClaimsStr, authMod) {
    const authClaims = JSON.parse(authClaimsStr);
    const accessAbilities: any = [];
    Object.keys(authClaims)
      .filter((siteId) => authClaims[siteId].hasOwnProperty(authMod)) // eslint-disable-line no-prototype-builtins
      .forEach((siteId) => {
        const modClaims = authClaims[siteId][authMod];
        const packedString = JSON.stringify({ [siteId]: modClaims });
        const claimForTheSite = claims.deserializeClaimsForModule(packedString, authMod);
        claimForTheSite[siteId].forEach((apiRole) => {
          accessAbilities.push({
            actions: apiRole,
            subject: `${authMod}-${siteId}`,
          });
        });
      });

    return accessAbilities;
  }

  static deserializeClaimsForAuthModuleForSites(targetSiteIds, authClaimsStr, authMod) {
    const authClaims = JSON.parse(authClaimsStr);
    const accessAbilities: any = [];
    Object.keys(authClaims)
      .filter((siteId) => authClaims[siteId].hasOwnProperty(authMod)) // eslint-disable-line no-prototype-builtins
      .forEach((siteId) => {
        if (targetSiteIds.includes(siteId)) {
          const modClaims = authClaims[siteId][authMod];
          const packedString = JSON.stringify({
            [siteId]: modClaims,
          });
          const claimForTheSite = claims.deserializeClaimsForModule(packedString, authMod);
          claimForTheSite[siteId].forEach((apiRole) => {
            if (!accessAbilities.find((el) => el.actions === apiRole && el.subject === authMod)) {
              accessAbilities.push({
                actions: apiRole,
                subject: authMod,
              });
            }
          });
        }
      });

    return accessAbilities;
  }

  static hasModuleMissing(site, module) {
    return (
      !site ||
      (site &&
        (!site.modules ||
          (site.modules && site.modules.values && Array.from(site.modules.values).indexOf(module) === -1)))
    );
  }

  static hasExtraModuleMissing(extraModuleAccessAbilities, extraModule) {
    return !extraModuleAccessAbilities.find((el) => el.subject.includes(extraModule));
  }

  static checkVrsInternalAccess(currentUser) {
    // get the abilities for the current user.
    if (currentUser) {
      return getUserIdAttributes(currentUser)
        .then((tokenDecoded) => {
          Logger.of('checkVrsInternalAccess').info('Token decoded during this feature', tokenDecoded);

          let vrsInternalAccessAbilities: any = [];
          try {
            vrsInternalAccessAbilities = Utils.deserializeClaimsForAuthModule(
              tokenDecoded.authClaims,
              authModules.VRS_OPERATIONS_AUTH_MODULE
            );
            Logger.of('checkVrsInternalAccess').info('Api claims unpacked', vrsInternalAccessAbilities);
          } catch (e) {
            Logger.of('checkVrsInternalAccess').error('Failed to parse auth claims', e);
          }

          return vrsInternalAccessAbilities;
        })
        .catch((err) => {
          Logger.of('checkVrsInternalAccess').error('Failed to decode user token', err);
          return [];
        });
    }

    return Promise.resolve([]);
  }

  static checkVrsAbilities(currentUser) {
    // get the abilities for the current user.
    if (currentUser) {
      return getUserIdAttributes(currentUser)
        .then((tokenDecoded) => {
          Logger.of('checkVrsAbilities').info('Token decoded during this feature', tokenDecoded);

          let vrsInternalAccessAbilities: any = [];
          try {
            vrsInternalAccessAbilities = Utils.deserializeClaimsForAuthModule(
              tokenDecoded.authClaims,
              authModules.VRS_OPERATIONS_AUTH_MODULE
            );
            Logger.of('checkVrsInternalAccess').info('Api claims unpacked', vrsInternalAccessAbilities);
          } catch (e) {
            Logger.of('checkVrsInternalAccess').error('Failed to parse auth claims', e);
          }

          let vrsReportingAccessAbilities: any = [];
          try {
            vrsReportingAccessAbilities = Utils.deserializeClaimsForAuthModule(
              tokenDecoded.authClaims,
              authModules.VRS_REPORTING_AUTH_MODULE
            );
            Logger.of('checkVrsAbilities').info('Api claims unpacked', vrsReportingAccessAbilities);
          } catch (e) {
            Logger.of('checkVrsAbilities').error('Failed to parse auth claims', e);
          }

          let vrsSuperUserAbilities: any = [];
          try {
            vrsSuperUserAbilities = Utils.deserializeClaimsForAuthModule(
              tokenDecoded.authClaims,
              authModules.VRS_SUPERUSER_AUTH_MODULE
            );
            Logger.of('checkVrsAbilities').info('Api claims unpacked', vrsSuperUserAbilities);
          } catch (e) {
            Logger.of('checkVrsAbilities').error('Failed to parse auth claims', e);
          }

          let vrsExternalAbilities: any = [];
          try {
            vrsExternalAbilities = Utils.deserializeClaimsForAuthModule(
              tokenDecoded.authClaims,
              authModules.VRS_AUTH_MODULE
            );
            Logger.of('checkVrsAbilities').info('Api claims unpacked', vrsExternalAbilities);
          } catch (e) {
            Logger.of('checkVrsAbilities').error('Failed to parse auth claims', e);
          }

          return {
            vrsInternalAccessAbilities,
            vrsReportingAccessAbilities,
            vrsSuperUserAbilities,
            vrsExternalAbilities,
          };
        })
        .catch((err) => {
          Logger.of('checkVrsInternalAccess').error('Failed to decode user token', err);
          return {
            vrsInternalAccessAbilities: [],
            vrsReportingAccessAbilities: [],
            vrsSuperUserAbilities: [],
            vrsExternalAbilities: [],
          };
        });
    }

    return Promise.resolve([]);
  }

  static readonly UserTypeVrsInternal = 'Vrs_Internal';
  static readonly UserTypeVrsExternal = 'Vrs_External';
  static readonly UserTypeVrsNone = 'Vrs_None';

  static vrsTypeForSite = (vrsAbilities, siteId) => {
    // DE-3424 enumerates Videojet, Videojet service, Videojet-contractAdmin, Videojet-CSE, Videojet-SalesUser
    // -- these are all of the current vrsOperationsRoles, so no filtering needed. likewise with vrsSuperUser
    const internal = vrsAbilities.vrsInternalAccessAbilities.concat(vrsAbilities.vrsSuperUserAbilities);
    if (internal.length > 0) return this.UserTypeVrsInternal;
    // external is site based, find abilities by site
    const external = vrsAbilities.vrsExternalAbilities.filter((a) => a.subject.endsWith(`-${siteId}`));
    return external.length > 0 ? this.UserTypeVrsExternal : this.UserTypeVrsNone;
  };
  static getTrackingValuesFromQueryParameters(search) {
    let siteId = '';
    let companyId = '';
    let TrafficSource = '';
    let TrafficID = '';
    let SFDCRecord = '';

    const q: any = qstr.parse(search);
    if (q.companyId) {
      companyId = q.companyId;
    }
    if (q.siteId) {
      siteId = q.siteId;
    }

    if (q.TrafficSource) {
      TrafficSource = q.TrafficSource;
    }

    if (q.TrafficID) {
      TrafficID = q.TrafficID;
    }

    if (q.SFDCRecord) {
      SFDCRecord = q.SFDCRecord;
    }

    return {
      siteId,
      companyId,
      TrafficSource,
      TrafficID,
      SFDCRecord,
    };
  }

  static getCompanyAndSiteIdFromPathName(pathname) {
    let siteId = '';
    let companyId = '';
    // Check vrs/companyId/siteId
    const patternCompanySite =
      /vrs\/company\/(\d+|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-{3}[0-9a-fA-F]{12})\/site\/(\d+|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-{3}[0-9a-fA-F]{12})\//;
    const matchCompanySite = pathname.match(patternCompanySite);
    if (matchCompanySite && matchCompanySite[1] && matchCompanySite[2]) {
      companyId = matchCompanySite[1];
      siteId = matchCompanySite[2];
    } else {
      const patternSite = /site\/(\d+|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-{3}[0-9a-fA-F]{12})\//;
      const matchSite = pathname.match(patternSite);

      if (matchSite && matchSite[1]) {
        siteId = matchSite[1];
      }
    }

    return {
      siteId,
      companyId,
    };
  }

  static getPrinterId(pathname) {
    let printerId = '';
    const patternPrinter = /device\/([\w-\d]+)/;
    const matchPrinter = pathname.match(patternPrinter);

    const patternPrinter2 = /device-ts\/([\w-\d]+)/;
    const matchPrinter2 = pathname.match(patternPrinter2);

    if (matchPrinter && matchPrinter[1]) {
      printerId = matchPrinter[1];
    } else if (matchPrinter2 && matchPrinter2[1]) {
      printerId = matchPrinter2[1];
    }

    return printerId;
  }

  static async userTokens() {
    const currentUser: any = await authLib.getCurrentUser();
    if (currentUser && currentUser.userDataKey && currentUser.storage) {
      const key = currentUser.userDataKey.replace('.userData', '');
      const accessToken = currentUser.storage[`${key}.accessToken`]
        ? currentUser.storage[`${key}.accessToken`]
        : undefined;
      const idToken = currentUser.storage[`${key}.idToken`] ? currentUser.storage[`${key}.idToken`] : undefined;
      const refreshToken = currentUser.storage[`${key}.refreshToken`]
        ? currentUser.storage[`${key}.refreshToken`]
        : undefined;
      return {
        idToken,
        accessToken,
        refreshToken,
      };
    }
  }

  static setValue(target: any, source: any, keys: Array<string>) {
    for (const key of keys) {
      if (source[key] !== undefined) {
        target[key] = source[key];
      }
    }

    return target;
  }

  static getDatePartAsISO(date: Date): string {
    if (date instanceof Date) {
      return date.toISOString().replace(/T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, '');
    }
    return '';
  }

  static getHostName(): string {
    let hostName = '';
    if (typeof window !== 'undefined') {
      hostName = window.location.host;
      switch (hostName) {
        case 'localhost:3000':
        case 'localhost:9000':
        case 'vccm.dev.videojetcloud.com':
          Logger.of('App.index').info('setting stageName dev.');
          break;

        case 'vccm.qa.videojetcloud.com':
          Logger.of('App.index').info('setting stageName qa.');
          break;

        case 'vccm.stage.videojetcloud.com':
          Logger.of('App.index').info('setting stageName stage.');
          break;
        default:
          Logger.of('App.index').info(`couldn't find case for ${hostName}`);
      }
    }

    return hostName;
  }

  static isProductionLike(): boolean {
    const hostName = Utils.getHostName();
    return (
      hostName.indexOf('localhost') === -1 &&
      hostName.indexOf('.qa.') === -1 &&
      hostName.indexOf('.stage.') === -1 &&
      hostName.indexOf('.dev.') === -1
    );
  }

  static isQaLike(): boolean {
    const hostName = Utils.getHostName();
    return hostName.indexOf('.qa.') !== -1;
  }

  static isDevLike(): boolean {
    const hostName = Utils.getHostName();
    return hostName.indexOf('localhost') !== -1 || hostName.indexOf('.dev.') !== -1;
  }

  static companyAndSiteInCookies(): boolean {
    const cookieCompanyId = new Cookies().get('LastCompany');
    const cookieSiteId = new Cookies().get('LastSite');
    return cookieCompanyId && cookieSiteId && cookieCompanyId !== '0' && cookieSiteId !== '0';
  }

  static getCompanyCookie() {
    const cookieCompanyId = new Cookies().get('LastCompany');
    return cookieCompanyId || '0';
  }

  static getPlantCookie() {
    const cookieSiteId = new Cookies().get('LastSite');
    if (!Utils.IsIdGuid(cookieSiteId)) {
      return cookieSiteId || '0';
    }

    return '0';
  }

  static IsIdGuid(id) {
    const guidRegex = /[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}/;
    return guidRegex.test(id);
  }

  static setCompanyAndPlantCookie(companyId, plantId) {
    const cookies = new Cookies();
    cookies.set('LastCompany', companyId, {
      path: '/',
      maxAge: 365 * 24 * 60 * 60,
    });

    if (!Utils.IsIdGuid(plantId)) {
      cookies.set('LastSite', plantId, {
        path: '/',
        maxAge: 365 * 24 * 60 * 60,
      });
    }
  }

  static setCompanyCookie(companyId) {
    const cookies = new Cookies();
    cookies.set('LastCompany', companyId, {
      path: '/',
      maxAge: 365 * 24 * 60 * 60,
    });
  }

  static setPlantCookie(plantId) {
    const cookies = new Cookies();
    cookies.set('LastSite', plantId, {
      path: '/',
      maxAge: 365 * 24 * 60 * 60,
    });
  }

  static toSimpleISOString(value: Date) {
    if (!value) {
      return '';
    }
    return value.toISOString().replace(/:(\d{2})\.(\d{3})Z/, '');
  }

  static getDurationValue(duration: string) {
    if (duration && duration.length > 0) {
      let value = 0;
      const durationParts = duration.replace(/</g, '').split(':');
      for (const part of durationParts) {
        const timeType = part ? part.substring(part.length - 1) : '';
        const timePart = part ? Number(part.substring(0, part.length - 1)) : 0;
        if (timeType === 'd') {
          value += timePart * 3600 * 24;
        } else if (timeType === 'h') {
          value += timePart * 3600;
        } else if (timeType === 'm') {
          value += timePart * 60;
        } else if (timeType === 's') {
          value += timePart;
        }
      }

      return value;
    }
    return 0;
  }

  static getStartAndEndDate(timeRange: ITimeRange) {
    let start;
    let end;
    switch (timeRange.value) {
      case '1':
        end = new Date();
        start = new Date(end.getTime() - 1 * 60 * 60000);
        break;
      case '4':
        end = new Date();
        start = new Date(end.getTime() - 4 * 60 * 60000);
        break;
      case '8':
        end = new Date();
        start = new Date(end.getTime() - 8 * 60 * 60000);
        break;
      case '24':
        end = new Date();
        start = new Date(end.getTime() - 24 * 60 * 60000);
        break;
      case '168':
        end = new Date();
        start = new Date(end.getTime() - 168 * 60 * 60000);
        break;
      case '720':
        end = new Date();
        start = new Date(end.getTime() - 720 * 60 * 60000);
        break;
      case '1440':
        end = new Date();
        start = new Date(end.getTime() - 1440 * 60 * 60000);
        break;
      case '2160':
        end = new Date();
        start = new Date(new Date().getTime() - 2160 * 60 * 60000);
        break;
      default:
        end = timeRange.end;
        start = timeRange.start;
    }

    return {
      start,
      end,
    };
  }

  static evaluateAsUtc(momentDate: any, isStartOfDay: boolean) {
    const dateValue = momentDate.toDate();
    if (isStartOfDay) {
      dateValue.setHours(0, 0, 0, 0);
    } else {
      dateValue.setHours(23, 59, 59, 999);
    }

    return moment
      .utc([
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate(),
        dateValue.getHours(),
        dateValue.getMinutes(),
        dateValue.getSeconds(),
        dateValue.getMilliseconds(),
      ])
      .toISOString();
  }

  static evaluateAsUtcStartOrEndOfDay(dateValue: any, isStartOfDay: boolean) {
    if (isStartOfDay) {
      dateValue.setHours(0, 0, 0, 0);
    } else {
      dateValue.setHours(23, 59, 59, 999);
    }

    return moment.utc([
      dateValue.getFullYear(),
      dateValue.getMonth(),
      dateValue.getDate(),
      dateValue.getHours(),
      dateValue.getMinutes(),
      dateValue.getSeconds(),
      dateValue.getMilliseconds(),
    ]);
  }

  static convertToUtc(momentDate: any) {
    const dateValue = momentDate.toDate();
    const result = moment.utc(dateValue).toISOString();
    return result;
  }

  static evaluateAsUtcSimple(momentDate: any) {
    const dateValue = momentDate.toDate();
    dateValue.setHours(0, 0, 0, 0);

    return yyyymmddToddmmyyyy(
      moment
        .utc([
          dateValue.getFullYear(),
          dateValue.getMonth(),
          dateValue.getDate(),
          dateValue.getHours(),
          dateValue.getMinutes(),
          dateValue.getSeconds(),
          dateValue.getMilliseconds(),
        ])
        .format('YYYY-MM-DD')
    );
  }

  static getRedirectUrl(stage: string) {
    switch (stage) {
      case 'production':
        return 'https://videojetcloud.com/login';
      default:
        return `https://vccm.${stage}.videojetcloud.com/login`;
    }
  }

  static getSSOUrl(email: string) {
    const stage = this.getStage();
    if (stage == null) {
      return '';
    }

    const emailLower = email.toLocaleLowerCase();
    const ssoCustomer = federatedIdentityInfo.SSOCustomers.find(
      (def) => emailLower.endsWith(def.domain) && def.stages.find((s) => s === stage) != null
    );
    return ssoCustomer != null ? this.generateNameSSO(stage, ssoCustomer.name) : '';
  }

  static generateNameSSO = (stage: string, name: string) => {
    return `${config.cognito.OATH_TOKEN_URL}/authorize?identity_provider=${name}-${stage[0].toUpperCase()}${stage.slice(
      1
    )}&redirect_uri=${this.getRedirectUrl(stage)}&response_type=CODE&client_id=${
      config.cognito.APP_CLIENT_ID
    }&scope=email openid phone profile`;
  };

  static formatWithThousands = (n: any, _, fix: number, locale: string) => {
    if (n === null || n === undefined) {
      return n;
    }
    // exclude 0000 or 012234
    if (isNaN(n) || (n.toString().substr(0, 1) === '0' && n.toString().substr(0, 2) !== '0.')) {
      return n;
    }
    const nNumber = Number(n);

    if (Math.round(nNumber) === nNumber) {
      return nNumber.toLocaleString(locale, { maximumFractionDigits: fix});
    }

    return Number(nNumber.toFixed(fix)).toLocaleString(locale, { maximumFractionDigits: fix });
  };

  static IsValidRegEx = (regExpStr) => {
    let isValid = true;
    const regExpStrSimple =
      regExpStr && typeof regExpStr === 'string' && regExpStr[0] === '/' && regExpStr[regExpStr.length - 1] === '/'
        ? regExpStr.slice(1, regExpStr.length - 1)
        : regExpStr;
    try {
      new RegExp(regExpStrSimple);
    } catch (_) {
      isValid = false;
    }

    return isValid;
  };

  static IsFieldInvalid = (fieldDescription, fieldValue, errorObject?) => {
    const field = fieldDescription.field;
    const { required, validValues, simpleRegEx, type } = fieldDescription;

    const validValuesArray = validValues ? validValues.map((el) => el.value) : null;

    const result =
      (errorObject && errorObject[field]) ||
      (required &&
        (fieldValue === undefined ||
          fieldValue === null ||
          fieldValue === '' ||
          !simpleRegEx.test(fieldValue.toString().trim()) ||
          (type === 'Regular Expression' && !Utils.IsValidRegEx(fieldValue)) ||
          (validValuesArray && !validValuesArray.includes(fieldValue.toString().trim())))) ||
      (!required &&
        validValuesArray &&
        fieldValue !== undefined &&
        fieldValue !== null &&
        fieldValue !== '' &&
        !validValuesArray.includes(fieldValue.toString().trim())) ||
      (!required &&
        fieldValue !== undefined &&
        fieldValue !== null &&
        fieldValue !== '' &&
        !simpleRegEx.test(fieldValue.toString().trim())) ||
      (!required &&
        type === 'Regular Expression' &&
        fieldValue !== undefined &&
        fieldValue !== null &&
        fieldValue !== '' &&
        !Utils.IsValidRegEx(fieldValue));
    return !!result;
  };

  static UpdateFieldInvalidErrorObject = (fieldDescription, fieldValue, activeErrorObject) => {
    const field = fieldDescription.field;
    const { required, validValues, simpleRegEx, type } = fieldDescription;

    const validValuesArray = validValues ? validValues.map((el) => el.value) : null;

    if (required && (fieldValue === undefined || fieldValue === null || fieldValue === '')) {
      activeErrorObject[field] = activeErrorObject[field] || [];
      activeErrorObject[field].push('Empty value in a required field');
    } else if (required && !simpleRegEx.test(fieldValue.toString().trim())) {
      activeErrorObject[field] = activeErrorObject[field] || [];
      activeErrorObject[field].push('Invalid value in a required field');
    } else if (required && type === 'Regular Expression' && !Utils.IsValidRegEx(fieldValue)) {
      activeErrorObject[field] = activeErrorObject[field] || [];
      activeErrorObject[field].push('Invalid regular expression in a required field');
    } else if (required && validValuesArray && !validValuesArray.includes(fieldValue.toString().trim())) {
      activeErrorObject[field] = activeErrorObject[field] || [];
      activeErrorObject[field].push('Invalid value in a required field');
    } else if (
      !required &&
      validValuesArray &&
      fieldValue !== undefined &&
      fieldValue !== null &&
      fieldValue !== '' &&
      !validValuesArray.includes(fieldValue.toString().trim())
    ) {
      activeErrorObject[field] = activeErrorObject[field] || [];
      activeErrorObject[field].push('Invalid value in a non required field');
    } else if (
      !required &&
      fieldValue !== undefined &&
      fieldValue !== null &&
      fieldValue !== '' &&
      !simpleRegEx.test(fieldValue.toString().trim())
    ) {
      activeErrorObject[field] = activeErrorObject[field] || [];
      activeErrorObject[field].push('Invalid type in a required field');
    } else if (
      !required &&
      type === 'Regular Expression' &&
      fieldValue !== undefined &&
      fieldValue !== null &&
      fieldValue !== '' &&
      !Utils.IsValidRegEx(fieldValue)
    ) {
      activeErrorObject[field] = activeErrorObject[field] || [];
      activeErrorObject[field].push('Invalid regular expression in a non required field');
    }
  };

  static serializeNullable = (value: any, asString = true) => {
    let result: string | null = null;
    if (value !== null && value !== undefined) {
      if (asString) {
        result = `"${value.toString().trim().replace(/\\/g, '\\\\').replace(/"/g, '\\"').replace(/\n/g, '\\n').replace(/\r/g, '\\r')}"`;
      } else {
        result = value === "" ? null : value;
      }
    }

    return result;
  };

  static IsNullEmptyOrUndefined(value) {
    return value === null || value === '' || value === undefined;
  }

  static checkEmptyValue(value, key) {
    return value
      ? Array.isArray(value[key])
        ? value[key].join(', ')
        : !Utils.IsNullEmptyOrUndefined(value[key])
        ? value[key]
        : ''
      : null;
  }

  static formatValue = (value, language, decimalValue, locale) => {
    return !isNaN(value) && value !== ''
      ? decimalValue !== null && decimalValue != undefined && decimalValue != -1
        ? Utils.formatWithThousands(value, language, decimalValue, locale)
        : value
      : value;
  };

  static convertDataTagsToParameterSelection = (assignedDataTags: IDataTagNameAssignment[]) => {
    const dataSubmitObject: {
      [key: string]: IParameterSelectionItem[];
    } = {};
    for (const item of assignedDataTags) {
      const modelName = item.modelId === '-1' ? 'CommonTag' : item.modelId;
      dataSubmitObject[modelName] = dataSubmitObject[modelName] || [];
      dataSubmitObject[modelName].push({
        DataTagId: item.dataTagId,
        MessageTagId: item.messageTagId,
        DataTagName: item.dataTagName,
        MessageTagName: item.messageTagName,
      });
    }

    const dataSubmitArray: IParameterSelection[] = Object.keys(dataSubmitObject).map((key) => ({
      Model: key,
      DataTags: dataSubmitObject[key],
    }));

    return dataSubmitArray;
  };

  static convertDataTagsToExtraColumns = (
    assignedDataTags: IDataTagNameAssignment[],
    devices: Partial<IServiceModel>[] | undefined,
    _T
  ) => {
    const columnObject: {
      [key: string]: GridColDef;
    } = {};

    const dataTags: Partial<IDataTagNameAssignment>[] = assignedDataTags.map((el) => {
      const columnKey = el.dataTagName;
      if (el && columnKey && !columnObject[columnKey]) {
        // Todo Add Sortable, selector and sortComparer based on base type
        const columnKeyWithoutSpace = el.dataTagName?.replace(/ /g, '');

        return { ...el, ['columnKey']: columnKeyWithoutSpace };
      }
      return { ...el };
    });

    const uniqueDeviceValues = {};
    const singleSelectRequiredTags = {};
    const tagTypes = {};

    // Check Unique Values for dropdown filter
    if (devices) {
      for (const item of dataTags) {
        if (item['columnKey']) {
          uniqueDeviceValues[item['columnKey']] = {};
        }
      }

      for (const device of devices) {
        for (const dataTag of dataTags) {
          const columnKey = dataTag['columnKey'];
          if (
            device &&
            columnKey &&
            uniqueDeviceValues[columnKey] &&
            device[columnKey] !== undefined &&
            device[columnKey] !== null
          ) {
            uniqueDeviceValues[columnKey][device[columnKey]] = [device[columnKey]];

            if (dataTag.baseValueSpecialType) {
              if (dataTag.baseValueSpecialType === 'DateTime') {
                tagTypes[columnKey] = 'String'; //'DateTime';
              } else if (dataTag.baseValueSpecialType === 'Incremental') {
                tagTypes[columnKey] = 'Numeric'; // Numeric
              } else {
                tagTypes[columnKey] = 'String'; // String or State
              }
            } else if (dataTag.baseValueType) {
              if (dataTag.baseValueType === 'Numeric') {
                tagTypes[columnKey] = 'Numeric';
              } else {
                tagTypes[columnKey] = 'String';
              }
            } else {
              if (!isNaN(device[columnKey])) {
                tagTypes[columnKey] = tagTypes[columnKey] !== 'String' ? 'Numeric' : 'String';
              } else {
                tagTypes[columnKey] = 'String';
              }
            }
          }
        }
      }

      const devicesLength = devices.length;

      for (const dataTag of dataTags) {
        const columnKey = dataTag['columnKey'];

        if (
          columnKey &&
          devicesLength > 0 &&
          uniqueDeviceValues[columnKey] &&
          Object.keys(uniqueDeviceValues[columnKey]).length / devicesLength < 0.1
        ) {
          singleSelectRequiredTags[columnKey] = true;
        }
      }
    }

    for (const dataTag of dataTags) {
      const columnKey = dataTag['columnKey'];
      if (!columnObject[columnKey]) {
        let extraProps = {};

        // Single select only applicable to string types
        if (tagTypes[columnKey] !== 'Numeric' && singleSelectRequiredTags[columnKey]) {
          extraProps = {
            type: 'singleSelect',
            valueOptions: Array.from(new Set(Object.keys(uniqueDeviceValues[columnKey]))).filter(Boolean),
          };
        } else if (tagTypes[columnKey] === 'Numeric') {
          extraProps = {
            type: 'number',
          };
        }

        const columnDef: GridColDef = {
          field: columnKey,
          headerName: _T(columnKey),
          sortable: true,
          filterable:
            !singleSelectRequiredTags[columnKey] ||
            (singleSelectRequiredTags[columnKey] &&
              (Object.keys(uniqueDeviceValues[columnKey]).length > 1 ||
                (Object.keys(uniqueDeviceValues[columnKey]).length === 1 &&
                  Object.keys(uniqueDeviceValues[columnKey])[0] !== ''))),
          minWidth: 100,
          width: 150,
          headerAlign: 'center',
          align: 'center',
          ...extraProps,
        };

        columnObject[columnKey] = columnDef;
      }
    }

    const columndef = Object.keys(columnObject).map((key) => columnObject[key]);
    columndef.sort((a, b) => (a.headerName && b.headerName ? a.headerName.localeCompare(b.headerName) : 0));

    return columndef;
  };

  static throwErrorIfNecessary = (results) => {
    const error = results.errors && results.errors.length > 0 ? results.errors[0] : results.errors;
    if (error) {
      const errStr = extractErrors(error);
      if (
        errStr &&
        (errStr.indexOf('TooLarge') > -1 ||
          errStr.indexOf('size exceeded maximum') > -1 ||
          errStr.indexOf('Execution timed out') > -1)
      ) {
        throw new Error(
          'There is too much data to download for the selected time range.  Please select a smaller time range.'
        );
      } else {
        throw new Error(errStr || 'Devices cannot be loaded!');
      }
    } else {
      throw new Error('Devices cannot be loaded!');
    }
  };

  static findMinAndMax = (array): { min: number; max: number } => {
    if (array.length === 0) {
      return { min: 0, max: 0 };
    }

    let min = array[0];
    let max = array[0];

    for (let i = 1; i < array.length; i++) {
      if (array[i] < min) {
        min = array[i];
      } else if (array[i] > max) {
        max = array[i];
      }
    }

    return { min, max };
  };
}

export default Utils;
